import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { em } from 'polished'

import { i18n } from '../../../../i18n'

const LegendList = styled.ul`
  list-style: none;
  padding: 0;
  top: 30px;
  position: relative;
  ${({ large }) =>
    large &&
    `
    top: 40px;
  `}
`

const LegendItem = styled.li`
  padding: 0;
  line-height: 32px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`

const LegendIcon = styled.span`
  width: 17px;
  height: 17px;
  display: inline-block;
  background: ${({ theme }) => theme.boxBGColor};
  border-radius: 2px;
  margin-right: 9px;
  margin-top: 7px;
  align-self: flex-start;
  vertical-align: middle;
  ${({ fill }) =>
    fill &&
    `
    background: ${fill};
  `}
  ${({ circle }) =>
    circle &&
    `
    width: 25px;
    height: 25px;
    border-radius: ${em('25px')};
  `}
`
const LegendValue = styled.div`
  vertical-align: middle;
  line-height: 24px;
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const LegendCompare = styled.span`
  color: ${props => props.theme.fontColorLight};
  display: block;
  font-size: 15px;
  line-height: 20px;
`

const renderLegend = props => {
  const { payload, type, nabolag, city, category, large } = props
  const usedLabels = []
  const legendData = payload
    .map(item => {
      if (usedLabels.indexOf(item.value) === -1) {
        usedLabels.push(item.value)
        return item
      }
      return null
    })
    .filter(n => n !== null)
  return (
    <LegendList large={large}>
      {legendData.map((entry, index) => (
        <LegendItem key={`${category}-legend-${type}-${index.toString()}}`}>
          <LegendIcon fill={entry.color} circle />
          <LegendValue>
            {i18n.t(`${category}:${type}.${entry.value}WithValue`, {
              value: Math.round(entry.payload.value) || '0',
            })}
            <LegendCompare>
              {entry.payload.compareValue && city
                ? `${city}: ${Math.round(entry.payload.compareValue)}%`
                : ''}
            </LegendCompare>
          </LegendValue>
        </LegendItem>
      ))}
    </LegendList>
  )
}

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

renderLegend.defaultProps = {
  large: false,
}

const renderSquaredLegend = props => {
  const { payload, nabolag, city, large } = props
  return (
    <LegendList large={large}>
      {payload.map((entry, index) => (
        <LegendItem key={`${city}-legend-item-${index.toString()}`}>
          <LegendIcon fill={entry.color} />
          <LegendValue>
            {entry.value === 'neighborhood' || entry.value === 'value' ? nabolag : city}
          </LegendValue>
        </LegendItem>
      ))}
    </LegendList>
  )
}

renderSquaredLegend.propTypes = {
  payload: PropTypes.shape().isRequired,
  nabolag: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  large: PropTypes.bool,
}

renderSquaredLegend.defaultProps = {
  large: false,
}

export { LegendList, LegendItem, LegendIcon, renderLegend, renderSquaredLegend }
