import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { clearFix } from 'polished'

import media from '../layout/media'

import { LinkButton, H2 } from '../../ui'
import { withTranslation } from '../../../i18n'

const WidgetWrapper = styled.div`
  margin: 40px;
`
const Item = styled.div`
  box-sizing: border-box;
  line-height: 40px;
  padding: 5px 0;
  background-color: ${({ theme }) => theme.conversionBoxBackground};
  text-align: center;
  box-shadow: ${({theme}) => theme.themeShadow ||
    `0 0 2px rgba(0, 0, 0, 0.12), 
    0 2px 2px rgba(0, 0, 0, 0.24),
    0 0 8px rgba(0, 0, 0, 0.12);`
  };
  border-radius: 4px;
  min-height: 150px;

  h2 {
    margin-bottom: 0;
  }

  a {
    display: block;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${props => props.theme.spacing};
    position: relative;
    text-align: center;
    line-height: 24px;
    min-width: 250px;
    vertical-align: middle;

    color: ${({ theme }) => theme.conversionBoxButtonColor};
    background: ${({ theme }) => theme.conversionBoxButtonBackground};
    border: 2px solid ${({ theme }) => theme.conversionBoxButtonBackground};

    ${media.medium`
      display: block;
      max-width: 250px;
      position: relative;
    `};

    &:after {
      ${clearFix()};
    }
  }
`

const Content = styled.div`
  padding-bottom: 20px;
  font-size: 18px;
`

const TravelTimeButton = ({ nabolag, onTrackShow, type, t }) => {
  let profileType = type
  if (type === 'normal' && !nabolag.address) {
    profileType = 'normalNoAddress'
  }

  return (
    <WidgetWrapper>
      <Item>
        <H2 small bold centered>
          {t(`travel-time:page.title`)}
        </H2>
        <Content>{t(`travel-time:widget.${profileType}`, { address: nabolag.address })}</Content>
        <LinkButton route="travel-time-redirect" filledBg onClick={onTrackShow}>
          {t(`travel-time:link-text.${type}`)}
        </LinkButton>
      </Item>
    </WidgetWrapper>
  )
}

TravelTimeButton.propTypes = {
  t: PropTypes.func.isRequired,
  nabolag: PropTypes.shape().isRequired,
  onTrackShow: PropTypes.func,
  type: PropTypes.string,
}

TravelTimeButton.defaultProps = {
  onTrackShow: null,
  type: 'normal',
}

export default compose(withTranslation(['travel-time']))(TravelTimeButton)
