import scroll from 'scroll'
import ease from 'ease-component'
import scrollDoc from 'scroll-doc'

const scrollTo = id => {
  const doc = document.documentElement
  const page = scrollDoc()
  const scrollPos = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  if (id && scrollPos === 0) {
    const el = document.getElementById(id)
    if (el) {
      const rect = el.getBoundingClientRect()
      const scrollToPos = Math.round(rect.top)
      scroll.top(page, scrollToPos, { ease: ease.inOutQuad, duration: 500 })
    }
  }
}

export default scrollTo
