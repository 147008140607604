import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import {
  getRouteCalculation,
  getShowRouteCalculation
} from '../../store/nabolag/selectors'
import TravelTimeButton from '../travel-time-button'

const mapStateToProps = state => ({
  routeCalculation: getRouteCalculation(state),
  showRouteCalculation: getShowRouteCalculation(state)
})

const TravelTimeButtonContainer = React.memo(({ nabolag, onTrackShow, routeCalculation, showRouteCalculation }) =>
  showRouteCalculation ? (
    <TravelTimeButton nabolag={nabolag} onTrackShow={onTrackShow} type={routeCalculation.i18n} />
  ) : null,
)

TravelTimeButtonContainer.propTypes = {
  onTrackShow: PropTypes.func,
  // hoc props
  nabolag: PropTypes.shape().isRequired,
  // state props
  routeCalculation: PropTypes.shape().isRequired,
  showRouteCalculation: PropTypes.bool.isRequired,
}

TravelTimeButtonContainer.defaultProps = {
  onTrackShow: null,
}

export default compose(connect(mapStateToProps))(TravelTimeButtonContainer)
