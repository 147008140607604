import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { clearFix } from 'polished'
import storage from 'local-storage-fallback'

import { LinkButton, H2 } from '../../ui'
import AppContext from '../../hocs/AppContext'
import media from '../layout/media'

import { withTranslation } from '../../../i18n'

const boxShadow = (theme) => theme.themeShadow || `
  0 0 2px rgba(0, 0, 0, 0.12), 
  0 2px 2px rgba(0, 0, 0, 0.24),
  0 0 8px rgba(0, 0, 0, 0.12)
`;

const ComparisonWrapper = styled.div`
  ${({ theme }) =>
    theme &&
    `
      background-color: ${theme.comparisonWidgetBG};
      border: 0px solid ${theme.comparisonWidgetBorder};
      box-shadow: ${boxShadow(theme)};
      color: ${theme.fontColor};
      margin: ${theme.spacingLarge};
    `};
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  line-height: 40px;
  height: auto;
  min-height: 120px;
  padding: 5px 0;

  ${media.small`
    margin: ${props => props.theme.spacing};
    text-align: center;
  `};

  a {
    margin-left: ${props => props.theme.spacing};
    text-align: center;
    display: inline-block;
    line-height: 24px;
    white-space: nowrap;
    min-width: 250px;
    vertical-align: middle;
    color: ${({ theme }) => theme.comparisonWidgetLinkColor};

    ${media.medium`
      display: block;
      max-width: 250px;
      position: relative;
      margin: ${props => props.theme.spacing} auto 0 auto;
    `};

    &:after {
      ${clearFix()};
    }
  }
`

const ButtonWrapper = styled.div`
  margin-left: ${props => props.theme.spacing};
  margin-right: ${props => props.theme.spacing};

  a {
    display: block;
    max-width: 300px;
    overflow: hidden;
    text-overflow: elipsis;
    white-space: no-wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${props => props.theme.spacing};
    position: relative;
    background-color: ${({ theme }) => theme.buttonBackground};
    color: ${({ theme }) => theme.buttonColor};
  }
`

const ComparisonWidget = React.memo(({ nabolag, category, t, onTrackShow }) => {
  const selectedAddressId = storage.getItem('googleSelectedAddressId')
  const selectedAddressLabel = storage.getItem('googleSelectedAddressLabel')
  const isClient = typeof window !== 'undefined'
  const categoryStr = category ? t(`categories:${category}`).toLowerCase() : ''
  return (
    <ComparisonWrapper>
      <AppContext.Consumer>
        {context => (
          <React.Fragment>
            {selectedAddressId && isClient ? (
              <ButtonWrapper>
                <H2 small bold centered>
                  {t('common:compareAddressWidget')}
                </H2>
                <LinkButton
                  route="comparison"
                  params={{
                    id: context.query.id,
                    finnkode: nabolag.orderLineId,
                    comparisonId: selectedAddressId,
                    category: categoryStr,
                  }}
                  filledBg
                  onClick={onTrackShow}
                >
                  {selectedAddressLabel}
                </LinkButton>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <H2 small bold centered>
                  {t('common:compareAddressWidgetNoAddress')}
                </H2>
                <LinkButton
                  route="address"
                  params={{
                    id: context.query.id,
                  }}
                  filledBg
                  onClick={onTrackShow}
                >
                  {t('common:compareAddressWidgetNoAddressLinkText')}
                </LinkButton>
              </ButtonWrapper>
            )}
          </React.Fragment>
        )}
      </AppContext.Consumer>
    </ComparisonWrapper>
  )
})

ComparisonWidget.propTypes = {
  category: PropTypes.string,
  nabolag: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
  onTrackShow: PropTypes.func,
}
ComparisonWidget.defaultProps = {
  onTrackShow: null,
  category: '',
}

export default withTranslation(['common', 'categories'])(ComparisonWidget)
