import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import styled, { withTheme } from 'styled-components'

import { withTranslation } from '../../../../i18n'

const CurveWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  svg {
    width: 100%;
    display: block;
  }
`
const LegendList = styled.ul`
  display: flex;
  padding: 0;
`
const LegendItem = styled.li`
  flex: 1 0 25%;
  font-size: 18px;
  list-style: none;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    vertical-align: top;
  }
  svg {
    margin-right: 6px;
  }
  ${({ small }) =>
    small &&
    `
    font-size: 15px;
  `};
`
const LegendItemNorth = styled.li`
  flex: 1 0 12.5%;
  list-style: none;
  overflow: hidden;
`

const LegendIcon = styled.i`
  ${({ fill }) =>
    fill &&
    `
    background-color: ${fill};
  `};
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 5px;
  border-radius: 100%;
`
const SuncurvesText = styled.p`
  font-size: 15px;
  margin: 10px;
  line-height: 21px;
  text-align: justify;
`
const SunIcon = props => (
  <path
    transform={`translate(${props.x}, ${70 - props.y}) scale(0.8) translate(-15, -10)`}
    d="M20,14.2l-0.7,3.1l-3.1-1.1l-2.6,1.9L12,15.4l-3.2-0.2l0.5-3.2L6.9,9.9 l2.4-2.1L8.9,4.6l3.2,0l1.8-2.7l2.5,2l3.1-1l0.6,3.2l3,1.3l-1.6,2.8L23,13L20,14.2L20,14.2z"
    fill="rgb(253, 205, 108)"
    stroke="rgb(255, 150, 0)"
    strokeWidth="2"
  />
)

const CompassStrokes = () => (
  <g>
    <line x1="0"   x2="0"  y1="-5"  y2="0" stroke="rgb(71, 68, 69)" strokeWidth="1" />
    <line x1="90"  x2="90" y1="-5"  y2="0" stroke="rgb(71, 68, 69)" strokeWidth="1" />
    <line x1="180" x2="180" y1="-5" y2="0" stroke="rgb(71, 68, 69)" strokeWidth="1" />
    <line x1="270" x2="270" y1="-5" y2="0" stroke="rgb(71, 68, 69)" strokeWidth="1" />
    <line x1="360" x2="360" y1="-5" y2="0" stroke="rgb(71, 68, 69)" strokeWidth="1" />
  </g>
)

SunIcon.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

const SummerCurve = ({ data }) => (
  <g>
    {data.map(d => (
      <SunIcon x={d.x} y={d.y} key={`summer-hour-${d.hour}`} />
    ))}
  </g>
)

SummerCurve.propTypes = { data: PropTypes.arrayOf(PropTypes.shape()).isRequired }

const SpringCurve = ({ data, fill }) => (
  <g>
    {data.map(d => (
      <circle r={5} cx={d.x} cy={70 - d.y} key={`spring-hour-${d.hour}`} fill={fill} />
    ))}
  </g>
)

SpringCurve.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fill: PropTypes.string.isRequired,
}

const WinterCurve = ({ data, fill }) => (
  <g>
    {data.map(d => (
      <circle r={4} cx={d.x} cy={70 - d.y} key={`winter-hour-${d.hour}`} fill={fill} />
    ))}
  </g>
)

WinterCurve.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fill: PropTypes.string.isRequired,
}

const ElevationElement = ({ data, fill }) => {
  const dataStr = `${data.map(
    (e, index) => `${index === 0 ? 'M ' : 'L '}${e.x}, -${e.y + 1}`,
  )} L 360 90 L 0 90 Z`
  return (
    <g transform="translate(0, 70)">
      <path fill={fill} d={dataStr} />
      <CompassStrokes />
    </g>
  )
}

ElevationElement.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fill: PropTypes.string.isRequired,
}

class SuncurvesChart extends React.PureComponent {
  constructor(props) {
    super(props)
    this.svgRef = React.createRef()
    this.state = {
      svgHeight: 62,
    }
  }

  componentDidMount() {
    if (this.svgRef.current) {
      const svgHeight = Math.round(this.svgRef.current.parentNode.offsetWidth * 0.194117647) + 1
      this.setState({ svgHeight })
    }
  }

  render() {
    const { data, t, theme } = this.props
    const { svgHeight } = this.state
    if (!data || !data.stats || !data.stats.summer) {
      return <div>No stats provider</div>
    }

    return (
      <div>
        <CurveWrapper style={{ height: `${svgHeight}px` }}>
          <svg viewBox="0 0 360 90" preserveAspectRatio="xMinYMin slice" ref={this.svgRef}>
            <SummerCurve data={data.suncurves.summer} />
            <SpringCurve data={data.suncurves.spring} fill={theme.sunCurves.spring} />
            <WinterCurve data={data.suncurves.winter} fill={theme.sunCurves.winter} />
            <ElevationElement
              data={data.elevation}
              svgHeight={svgHeight}
              fill={theme.sunCurves.elevation}
            />
          </svg>
        </CurveWrapper>
        <LegendList>
          <LegendItemNorth/>
          <LegendItem>{t('charts:east')}</LegendItem>
          <LegendItem>{t('charts:south')}</LegendItem>
          <LegendItem>{t('charts:west')}</LegendItem>
          <LegendItemNorth/>
        </LegendList>
        {data.stats ? (
          <LegendList>
            <LegendItem small>
              <svg width="18" height="18" viewBox="0 0 24 24">
                <SunIcon x={16} y={59} />
              </svg>
              <span>{data.stats.summer.date}</span>
            </LegendItem>
            <LegendItem small>
              <LegendIcon fill={theme.sunCurves.spring} />
              {data.stats.spring.date}
            </LegendItem>
            <LegendItem small>
              <LegendIcon fill={theme.sunCurves.winter} />
              {data.stats.winter.date}
            </LegendItem>
          </LegendList>
        ) : null}
        <SuncurvesText>{t('environment:SUNCURVES_TEXT')}</SuncurvesText>
      </div>
    )
  }
}

SuncurvesChart.propTypes = {
  data: PropTypes.shape().isRequired,
  theme: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
}

SuncurvesChart.defaultProps = { inCard: false }

export default compose(
  withTranslation(['common', 'charts', 'environment']),
  withTheme,
)(SuncurvesChart)
