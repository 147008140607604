import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'

import { getNabolagCategories } from '../store/nabolag/selectors'

export const categoriesShape = PropTypes.arrayOf(PropTypes.string)

const mapStateToProps = (state, ownProps) => {
  if (ownProps.categories) {
    return {
      categories: ownProps.categories,
    }
  }

  return {
    categories: getNabolagCategories(state),
  }
}

export const withCategories = Component => {
  const ExtendedCategories = props => <Component {...props} />
  ExtendedCategories.getInitialProps = async ctx => ({
    ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
  })

  return compose(connect(mapStateToProps))(ExtendedCategories)
}
