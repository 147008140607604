import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getAddressSuggestions } from '../../store/address/actions'
import { getSuggestionsNoHouseNumber } from '../../store/address/selectors'
import { getShowCompareBox } from '../../store/nabolag/selectors'
import ComparisonWidget from './ComparisonWidget'

const mapStateToProps = state => ({
  suggestions: getSuggestionsNoHouseNumber(state),
  showCompareBox: getShowCompareBox(state),
})

const mapDispatchToProps = dispatch => ({
  getAddresses: str => dispatch(getAddressSuggestions(str)),
})

const ComparisonContainer = React.memo(
  ({
    nabolag,
    category,
    getAddresses,
    suggestions,
    showCompareBox,
    onTrackShow,
    onTrackSubmit,
    onTrackInputFocus,
    onTrackSelectedAddress,
    onTrackSelectedNumber,
  }) =>
    showCompareBox ? (
      <ComparisonWidget
        nabolag={nabolag}
        category={category}
        getAddresses={getAddresses}
        suggestions={suggestions}
        onTrackSubmit={onTrackSubmit}
        onTrackInputFocus={onTrackInputFocus}
        onTrackSelectedAddress={onTrackSelectedAddress}
        onTrackSelectedNumber={onTrackSelectedNumber}
        onTrackShow={onTrackShow}
      />
    ) : null,
)

ComparisonContainer.propTypes = {
  // normal props
  category: PropTypes.string,
  onTrackSubmit: PropTypes.func,
  onTrackInputFocus: PropTypes.func,
  onTrackSelectedAddress: PropTypes.func,
  onTrackSelectedNumber: PropTypes.func,
  onTrackShow: PropTypes.func,

  // hoc props
  nabolag: PropTypes.shape().isRequired,

  // state props
  suggestions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showCompareBox: PropTypes.bool.isRequired,

  // dispatch props
  getAddresses: PropTypes.func.isRequired,
}

ComparisonContainer.defaultProps = {
  category: '',
  onTrackSubmit: null,
  onTrackSelectedAddress: null,
  onTrackSelectedNumber: null,
  onTrackShow: null,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ComparisonContainer)
