import { createSelector } from 'reselect'

export const getAddressState = state => state.address
export const getSuggestionArr = state => state.address.suggestions

export const getSuggestions = () =>
  createSelector(
    [getAddressState],
    address => address.suggestions,
  )

export const getSuggestionsNoHouseNumber = createSelector(
  [getAddressState],
  address => {
    return address.suggestions || []
  },
)

export const getFilteredSuggestions = createSelector(
  [getAddressState],
  address => {
    const limit = 10
    const { searchWord, suggestions, preferedCity } = address
    const digitEx = new RegExp(/\d/g)
    const containsDigit = searchWord.match(digitEx)
    // Sort and format suggestions depending on searchWord, prefered kommune etc.
    const res = suggestions
      .map(suggestion => {
        if (containsDigit) {
          const nrLen = suggestion.husnummer.length
          const houseNrAddresses = []
          for (let i = 0; i < nrLen; i += 1) {
            const houseNr = suggestion.husnummer[i]
            const testStr = `${suggestion.gatenavn} ${houseNr.number}`.toLowerCase()
            const regExp = new RegExp(searchWord, 'gi')
            if (regExp.exec(testStr)) {
              houseNrAddresses.push({
                id: `${houseNr.addressId}`,
                addressId: `${houseNr.addressId}`,
                label: `${suggestion.gatenavn} ${houseNr.number}`,
                name: suggestion.gatenavn,
                kommune: suggestion.kommune,
              })
            }
          }
          return houseNrAddresses
        }
        return {
          label: suggestion.gatenavn,
          id: `${suggestion.kommune.knr}-${suggestion.gatenavn}`,
          kommune: suggestion.kommune,
        }
      })
      .filter(suggestion => suggestion !== null) // Filter all null results
    return res
      .reduce((a, b) => a.concat(b), [])
      .sort((a, b) => {
        const aValue = a.kommune.name === preferedCity ? 1 : 0
        const bValue = b.kommune.name === preferedCity ? 1 : 0
        return bValue - aValue
      })
      .slice(0, limit)
  },
)
export const getAddressComparisonDataLoading = state => getAddressState(state).comparisonDataLoading

export const getAddressComparisonData = createSelector(
  [getAddressState],
  address => {
    if (!address || !address.comparisonData) {
      return {
        error: {
          type: 'no-comparison-data',
          message: 'No comparison data found',
          link: `../../adressesok`,
        },
      }
    }
    if (address.error) {
      return { error: address.error }
    }
    if (!address && !address.comparisonData) {
      return []
    }
    return address.comparisonData
  },
)
